/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import { RiPrinterLine, RiDownloadLine, RiCheckboxCircleLine } from "react-icons/ri"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const ComplianceChecklistPage = () => {
  const checklistSections = [
    {
      id: 'hiring-onboarding',
      title: 'Hiring & Onboarding Compliance',
      items: [
        'Employment Eligibility Verification (Form I-9)',
        'Equal Employment Opportunity Postings',
        'Employee Classification (Exempt vs. Non-Exempt)',
        'New Hire Reporting',
        'Employee Handbook Acknowledgment',
        'Non-Discrimination Policies',
        'Background Check Compliance'
      ]
    },
    {
      id: 'workplace-safety',
      title: 'Workplace Safety & Health',
      items: [
        'OSHA Safety Postings',
        'Emergency Action Plan',
        'Safety Training Records',
        'Hazard Communication Program',
        'Personal Protective Equipment Assessment',
        'First Aid Procedures',
        'Accident Reporting Procedures'
      ]
    },
    {
      id: 'wage-hour',
      title: 'Wage & Hour Compliance',
      items: [
        'Minimum Wage Requirements',
        'Overtime Calculations',
        'Pay Period Requirements',
        'Break Time Policies',
        'Time Keeping Records',
        'Payroll Tax Compliance',
        'Travel Time Policies'
      ]
    },
    {
      id: 'benefits-leave',
      title: 'Benefits & Leave Management',
      items: [
        'FMLA Policy and Procedures',
        'Benefits Summary Plan Descriptions',
        'PTO/Vacation Policies',
        'Leave Request Procedures',
        'ADA Accommodation Process',
        'Workers\' Compensation Coverage',
        'Health Insurance Compliance'
      ]
    }
  ]

  return (
    <Layout className="compliance-checklist-page">
      <SEO 
        title="HR Compliance Checklist | Performance Reviews" 
        description="Comprehensive HR compliance checklist covering hiring, workplace safety, wage & hour, and benefits compliance. Download or print for easy reference."
        keywords={[
          "HR compliance checklist",
          "HR compliance requirements",
          "workplace compliance checklist",
          "HR audit checklist",
          "employment law compliance",
          "HR compliance guide",
          "compliance requirements",
          "HR compliance tools",
          "HR compliance documentation",
          "compliance management checklist"
        ]}
      />
      
      {/* Hero Section */}
      <div sx={{
        bg: 'primaryMuted',
        pt: [3, 4],
        pb: [3, 4]
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3
        }}>
          <h1 sx={{
            fontSize: ['2rem', '2.5rem'],
            fontWeight: 700,
            textAlign: 'center',
            mb: 2,
            color: 'primary'
          }}>
            HR Compliance Checklist
          </h1>
          
          <p sx={{
            fontSize: '1.1rem',
            textAlign: 'center',
            maxWidth: '800px',
            mx: 'auto',
            mb: 3,
            lineHeight: 1.5,
            color: 'text'
          }}>
            Use this comprehensive checklist to ensure your organization maintains compliance with key HR requirements.
            Print or download for easy reference.
          </p>

          <div sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: 3,
            mb: 2
          }}>
            <button
              type="button"
              onClick={() => window.print()}
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                gap: 2,
                bg: 'primary',
                color: 'white',
                py: 2,
                px: 4,
                borderRadius: '6px',
                fontSize: '1.1rem',
                fontWeight: 600,
                border: 'none',
                cursor: 'pointer',
                transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
                },
                '@media print': {
                  display: 'none'
                }
              }}
            >
              <RiPrinterLine /> Print Checklist
            </button>
            <Link
              to="/contact"
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                gap: 2,
                bg: 'white',
                color: 'primary',
                py: 2,
                px: 4,
                borderRadius: '6px',
                fontSize: '1.1rem',
                fontWeight: 600,
                textDecoration: 'none',
                transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
                },
                '@media print': {
                  display: 'none'
                }
              }}
            >
              <RiDownloadLine /> Get Full Checklist
            </Link>
          </div>
        </div>
      </div>

      {/* Checklist Content */}
      <div sx={{
        maxWidth: '1200px',
        mx: 'auto',
        px: 3,
        py: [3, 4],
        '@media print': {
          py: 2
        }
      }}>
        <div sx={{
          display: 'grid',
          gridTemplateColumns: ['1fr', null, '1fr 1fr'],
          gap: 4,
          '@media print': {
            display: 'block'
          }
        }}>
          {checklistSections.map(section => (
            <div 
              key={section.id}
              sx={{
                bg: 'white',
                p: 3,
                borderRadius: '8px',
                boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                '@media print': {
                  boxShadow: 'none',
                  border: '1px solid #ddd',
                  mb: 3,
                  pageBreakInside: 'avoid'
                }
              }}
            >
              <h2 sx={{
                fontSize: ['1.4rem', '1.5rem'],
                color: 'primary',
                mb: 3,
                borderBottom: '2px solid',
                borderColor: 'primary',
                pb: 2
              }}>
                {section.title}
              </h2>
              <ul sx={{
                listStyle: 'none',
                p: 0,
                m: 0
              }}>
                {section.items.map(item => (
                  <li
                    key={item}
                    sx={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      mb: 2,
                      fontSize: '1.1rem'
                    }}
                  >
                    <div sx={{
                      width: '24px',
                      height: '24px',
                      border: '2px solid',
                      borderColor: 'primary',
                      borderRadius: '4px',
                      mr: 2,
                      flexShrink: 0,
                      '@media print': {
                        border: '2px solid #000'
                      }
                    }} />
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        {/* Notes Section */}
        <div sx={{
          mt: 4,
          p: 3,
          border: '2px dashed',
          borderColor: 'primary',
          borderRadius: '8px',
          '@media print': {
            mt: 3,
            border: '2px dashed #000'
          }
        }}>
          <h3 sx={{
            fontSize: '1.2rem',
            color: 'primary',
            mb: 2,
            '@media print': {
              color: 'text'
            }
          }}>
            Additional Notes & Action Items
          </h3>
          <div sx={{
            minHeight: '150px',
            '@media print': {
              borderTop: '1px solid #ddd'
            }
          }} />
        </div>

        {/* Print Footer */}
        <div sx={{
          display: 'none',
          '@media print': {
            display: 'block',
            mt: 4,
            pt: 2,
            borderTop: '1px solid #ddd',
            fontSize: '0.9rem',
            color: 'text',
            textAlign: 'center'
          }
        }}>
          Generated from Performance Reviews HR Compliance Tools
          <br />
          Visit www.performancereviews.com for more resources
        </div>
      </div>
    </Layout>
  )
}

export default ComplianceChecklistPage 